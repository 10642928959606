import {UseListHock} from "../../hooks/useListHock";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {useEffect, useId, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {ProfessionalModal} from "../../compoments/ProfessionalModal";
import {initPayment, setPromotion, setProvider, setStepper} from "../../store/slices/appointmentSlice";
import {STEPPERS} from "../../utils/Constants";
import {useAppDispatch} from "../../hooks/hook";
import {useNavigate} from "react-router-dom";

export const PromotionContent = () => {
  const userId = useId()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const {list, onReset, loadMore, loading, getDataList, page, nextPage} = UseListHock(buildUrlClient('promotions'));
  const [visible, setVisible] = useState(false);
  const [promo, setPromo] = useState({} as any);
  const [professionals, setProfessionals] = useState([]);
  useEffect(() => {
    (async () => await loadData())();
  }, []);

  const loadData =async () => {
    await getDataList(1);
    }
    const onPromotion = (item: any) => {
      const {profs} = item;
      setPromo(item);
      setProfessionals(profs);
      setVisible(true);
    }
    const onNextPage = () => {
    loadMore();
    }
    const onSelectProfessional = (professional: any) => {

      return navigate(`./../appointment/${professional.id}/${professional.independent}/${promo.id}`);
    }

  return <>
    {visible && <ProfessionalModal visible={visible}
                                   onClose={() => setVisible(false)}
                                   onSelect={(professional: any) => onSelectProfessional(professional)}
                                   list={professionals}/>
    }
    <InfiniteScroll
        dataLength={list.length} //This is important field to render the next data
        next={() => onNextPage()}
        hasMore={nextPage != null ? true : false}
        loader={<h4 style={{ textAlign: 'center' }}>Cargando...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            { list.length > 0 ? <b>Se listo todos los promociones</b> : <b>No hay promociones</b>}
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={() => console.log("local")}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center' }}></h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center' }}></h3>
        }
    >
      <section>
        <div className="container" >
          <div className="row mb-4">
            <div className="col-12 mx-auto">
              <h2 className="mb-0">Profesionales</h2>
            </div>
          </div>
          <div className="">
            <div className="tab-pane " id="course-pills-tabs-5" role="tabpanel"
                 aria-labelledby="course-pills-tab-5">
              <div className="row g-4">
                {list?.map((item: any, index: number) => (
                    <div key={index} className="col-sm-6 col-lg-4 col-xl-3"  onClick={() => onPromotion(item)}>
                      <div className="card shadow app-card">
                        <div className="card-img-overlay d-flex flex-column p-2 z-index-1">
                          {item.discount_percentage > 0 && <div className="card-img-overlay d-flex flex-column p-2 z-index-1">
                            <div>
                                        <span className="badge text-bg-dark">
                                        <i className="bi bi-percent text-warning me-2"></i>{(item.discount_percentage * 100).toFixed(2)}</span>
                            </div>
                          </div> }
                        </div>
                        <img src={item.link}
                             className="card-img-top img-service"
                             alt="course image" />
                        <div className="card-body pb-0">
                          <h5 className="card-title mb-0"><p>{item.name}</p></h5>
                          <p className="text-truncate-2 mb-2">{item.description}  </p>

                        </div>
                        <div className="card-footer pt-0 pb-3">
                          <div className="d-flex justify-content-end">
                            <div className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle"
                            onClick={() => onPromotion(item)}>
                              <i className="fas fa-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </InfiniteScroll>
  </>
}
