import {useEffect, useId} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {initPayment, setPromotion, setProvider, setStepper} from "../store/slices/appointmentSlice";
import {STEPPERS} from "../utils/Constants";
import {useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {getFeatured} from "../store/thunks/featuredThunk";
import {getByServices} from "../store/thunks/servicesThunk";
import {checkAddress} from "../store/thunks/AddressThunk";

export const ServicesAll = () => {
    const {featured, isLoading, page, next_page} = useAppSelector((state: RootState) => state.featured);
    const {typeId, searchText, departmentId} = useAppSelector((state: RootState) => state.filter);
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const userId = useId();

    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const loadData = () => {
        dispatch(getByServices(1, searchText, typeId, departmentId))
    }
    const onCard = (item: any) => {
        dispatch(checkAddress())
        dispatch(setPromotion({promotion: {}}));
        return navigate(`./../serviceDetail/${item.id}`);
      // return  navigate(`./../appointment/${item.id}/${item.independent}/0`);
    }
    const onNextPage = () => {
        if (next_page != null) {
            dispatch(getFeatured(page + 1, searchText, typeId, departmentId))
        }
    }
  return <>
      <InfiniteScroll
          dataLength={featured.length} //This is important field to render the next data
          next={() => onNextPage()}
          hasMore={next_page != null ? true : false}
          loader={<h4 style={{ textAlign: 'center' }}>Cargando...</h4>}
          endMessage={
              <p style={{ textAlign: 'center' }}>
                  { featured.length > 0 ? <b>Se listo todos los servicios</b> : <b>No hay servicios</b>}
              </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={() => console.log("local")}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}></h3>
          }
          releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}></h3>
          }
      >
          <section>
              <div className="container" >
                  <div className="row mb-4">
                      <div className="col-12 mx-auto">
                          <h2 className="mb-0">Servicios</h2>
                      </div>
                  </div>
                  <div className="">
                      <div className="tab-pane " id="course-pills-tabs-5" role="tabpanel"
                           aria-labelledby="course-pills-tab-5">
                          <div className="row g-4">
                              {featured?.map((item, index) => (
                                  <div key={index} className="col-sm-6 col-lg-4 col-xl-3"  onClick={() => onCard( item)}>
                                      <div className="card shadow app-card">
                                          {/*<div className="card-img-overlay d-flex flex-column p-2 z-index-1">*/}
                                          {/*    <div><span className="badge text-bg-dark"><i*/}
                                          {/*        className="bi bi-star-fill text-warning me-2"></i>{item.votes}</span>*/}
                                          {/*    </div>*/}
                                          {/*</div>*/}
                                          <img src={item.resize_image}
                                               className="card-img-top img-service"
                                               alt="course image" />
                                          <div className="card-body pb-0">
                                              {/*<div className="d-flex justify-content-between mb-2">*/}
                                              {/*    {item.type == 'Profesional' ? <p className="text-danger"><i*/}
                                              {/*        className="fas fa-user"></i> Profesional</p> : <p className="text-danger"><i*/}
                                              {/*        className="fas fa-home"></i> Salon</p>}*/}
                                              {/*    /!*<a href="#" className="text-danger"><i*!/*/}
                                              {/*    /!*    className="fas fa-heart"></i></a>*!/*/}
                                              {/*</div>*/}
                                              <div className="d-flex justify-content-between mb-2">
                                                  <p className="text-danger"> {item.name}</p>
                                                  {/*<a href="#" className="badge bg-purple bg-opacity-10 text-purple">All*/}
                                                  {/*    level</a>*/}

                                                  {/*<a href="#" className="text-danger"><i*/}
                                                  {/*    className="fas fa-heart"></i></a>*/}
                                              </div>
                                              <p className="text-truncate-2 mb-2">{item.price}</p>
                                              <p className="text-truncate-2 mb-2">{item.description}</p>

                                          </div>
                                          <div className="card-footer pt-0 pb-3">
                                              <div className="d-flex justify-content-between">
                                                  <div
                                                      className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle">
                                                      <i className="fas fa-calendar"></i></div>
                                                  <div
                                                      className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle">
                                                      <i className="fas fa-arrow-right"></i></div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </InfiniteScroll>
  </>
}
