import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {useEffect, useId, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getFeatured} from "../store/thunks/featuredThunk";
import {initPayment, setPromotion, setProvider, setStepper} from "../store/slices/appointmentSlice";
import {STEPPERS} from "../utils/Constants";
import {UseListHock} from "../hooks/useListHock";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {ProfessionalModal} from "./ProfessionalModal";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
export const CarouselPromotion = () => {
    const userId = useId()
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {typeId, searchText, departmentId} = useAppSelector((state: RootState) => state.filter);

    const {list, onReset, loadMore, loading, getDataList} = UseListHock(buildUrlClient('promotions'));
    const [professionals, setProfessionals] = useState([]);
    const [promo, setPromo] = useState({} as any);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        (async () => await loadData())();
    }, []);

    const loadData =async () => {
        await getDataList(1);
    }
    const onShowProfessional = (item: any) => {
        const {profs} = item;
        setPromo(item);
        setProfessionals(profs);
        setVisible(true);
    }
    const onSelectProfessional = (professional: any) => {
        // dispatch(setStepper({stepper: STEPPERS.CALENDAR}))
        // dispatch(setProvider({provider: {id: userId, independent: professional.independent}}));
        // dispatch(initPayment({id: userId, independent: professional.independent}));
        // dispatch(setPromotion({promotion: {
        //         id: promo?.id,
        //         name: promo.name,
        //         description: promo.description,
        //         date_end: promo.date_end,
        //         status: promo.status,
        //         discount_percentage: promo.discount_percentage}}));
        return navigate(`appointment/${professional.id}/${professional.independent}/${promo?.id}`);
    }
    return <> {list?.length > 0 &&  <div className={'container'}>
            <div className="col-12 d-sm-flex justify-content-between align-items-center " style={{paddingBottom: 10}}>
               <h2 className="mb-0">Promociones</h2>
               <a className="btn btn-sm btn-success-soft mb-0" href={'/promotion'}>Ver todos</a>
            </div>
        {visible && <ProfessionalModal visible={visible}
                                       onClose={() => setVisible(false)}
                                       onSelect={(professional: any) => onSelectProfessional(professional)} list={professionals} />}
            <div className={'row'}>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container carousel-change"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                    {list?.map((item, index) => (
                        <div key={index} className="col-sm-6 col-lg-4 col-xl-3 p-1"
                             style={{ width: "95%", height: "100%" }}
                             onClick={() => onShowProfessional(item)}>
                            <div className="card shadow   h-400px">
                                {item.discount_percentage > 0 && <div className="card-img-overlay d-flex flex-column p-2 z-index-1">
                                    <div>
                                        <span className="badge text-bg-dark">
                                        <i className="bi bi-percent text-warning me-2"></i>{(item.discount_percentage * 100).toFixed(2)}</span>
                                    </div>
                                </div> }
                                <img src={item.link}
                                     className="card-img-top img-service"
                                     alt="course image" />
                                <div className="card-body pb-0">

                                    <h5 className="card-title mb-0"><p >{item.name} </p></h5>
                                    <p className="text-truncate-2 mb-2">{item.description}</p>
                                </div>
                                <div className="card-footer pt-0 pb-3">
                                    <div className="d-flex justify-content-end">
                                        <div className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle align-content-end">
                                            <i className="fas fa-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </Carousel>
        </div>
    </div>
    }
    </>
}
